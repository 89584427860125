<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <div v-if="!show">
      <b-row v-if="!isUserActive && !user_complete">
        <b-col cols="12">
          <b-card>
            <b-card-body>
              <b-row
                align-h="center"
                align-v="center"
                style="flex-direction:row-reverse"
              >
                <b-col
                  cols="12"
                  sm="5"
                  md="3"
                  class="mb-3 mb-sm-0 d-flex justify-content-center"
                >
                  <feather-icon
                    variant="success"
                    icon="CheckCircleIcon"
                    size="150"
                    color="#94d56c"
                  />
                </b-col>
                <b-col
                  cols="12"
                  sm="7"
                  md="9"
                >
                  <h3 class="font-weight-bold mb-4">
                    {{ $t('user_no_active.register_success_confirm_email') }}
                  </h3>
                  <h3> {{ $t('user_no_active.description1') }}</h3>
                  <h3> {{ $t('user_no_active.description2') }}</h3>
                  <h3 class="text-danger">
                    {{ $t('user_no_active.email_process') }}
                  </h3>
                  <div class="mt-5 text-center">
                    <h4> {{ $t('user_no_active.email_resend') }}</h4>
                    <b-button
                      :disabled="sendMaliLoading"
                      variant="primary"
                      @click="resendActivationEmail"
                    >
                      {{ $t('user_no_active.resend_email_btn') }}
                    </b-button>
                  </div>
                </b-col>

              </b-row>

            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row
        v-if="isUserActive && !user_complete"
        class="d-flex flex-wrap flex-column justify-content-center align-items-center"
      >
        <b-col
          md="8"
          sm="12"
          class="mt-1"
        >
          <b-card style="height:100%">
            <b-card-body class="p-0 card-center-content">
              <h2>{{ $t(cardInfo.prevStep.header) }}</h2>
              <p>{{ $t('dashboard_page.use_exchange') }}</p>
              <a
                :href="exchangePath + `/${$i18n.locale}` + '/pendingOrder'"
                class="d-flex justify-content-center mt-3"
              >
                <b-button
                  class=" p-2 px-4"
                  variant="success"
                >
                  {{ $t('exchange') }}
                </b-button>
              </a>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          md="8"
          sm="12"
          class="mt-1"
        >
          <b-card style="height:100%">
            <b-card-body class="p-0 card-center-content">
              <h4>{{ $t(cardInfo.nextStep.header) }}</h4>
              <p>{{ $t(cardInfo.nextStep.description) }}</p>
            </b-card-body>
            <div class="text-center">
              <b-button
                class="p-2 px-4"
                variant="success"
                :to="cardInfo.nextStep.btn_link"
              >
                {{ $t(cardInfo.nextStep.btn_text) }}
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <!-- New Card -->

      <b-row>
        <b-col
          md="8"
          sm="12"
          class="mt-1 mx-auto"
        >
<!--          <b-card>-->
<!--            <b-card-body class="p-0 card-center-content">-->
<!--              <div>-->
<!--                <h2>{{ $t('dashboard_page.new') }}:</h2>-->
<!--                <p>{{ $t('dashboard_page.Buy-and-sell-Tether-with-Toman-desc') }}</p>-->
<!--              </div>-->
<!--              <a-->
<!--                href="https://t.me/TetherFiat"-->
<!--                target="_blank"-->
<!--                class="d-flex justify-content-center"-->
<!--              >-->
<!--                <b-button class=" p-2 px-2 buy-sell-btn text-black">-->
<!--                  {{ $t('Buy-and-sell-Tether-with-Toman') }}-->
<!--                </b-button>-->
<!--              </a>-->
<!--            </b-card-body>-->
<!--          </b-card>-->
        </b-col>
      </b-row>

      <b-row v-if="isUserActive && user_complete">
        <b-col
          md="8"
          sm="12"
          class="mt-1 mx-auto"
        >
          <b-card style="height:100%">
            <b-card-body class="p-0 card-center-content">
              <h2>{{ $t('dashboard_page.user_complete_message') }}</h2>
              <p>{{ $t('dashboard_page.use_exchange') }}</p>
              <a
                :href="exchangePath+ `/${$i18n.locale}` + '/pendingOrder'"
                class="d-flex justify-content-center"
              >
                <b-button
                  class=" p-2 px-4"
                  variant="success"
                >
                  {{ $t('exchange') }}
                </b-button>
              </a>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import ProfileApis from '@/services/apis/profile'
// import appFlow from '@/utils/appFlow'
import appFlow from '@/mixins/appFlow'

const profile = new ProfileApis()

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BOverlay,
  },

  mixins: [appFlow],
  data() {
    return {
      sendMaliLoading: false,
      exchangePath: process.env.VUE_APP_EXCHANGE_ADDRESS,
    }
  },
  mounted() {
    profile.get()
  },
  methods: {
    resendActivationEmail() {
      this.sendMaliLoading = true
      profile.reSendActivationMail({ email: this.$store.state.auth.user.email }).then(() => {
        this.$swal({
          title: this.$t('activationEmailSended'),
          icon: 'success',
          timer: 2500,
          showConfirmButton: true,
        })
      }).finally(() => {
        this.sendMaliLoading = false
      })
    },

    // setFlow() {
    //   this.show = true
    //   if (this.$store.state.profile.profileInfo.isActive === '1') {
    //     this.isUserActive = true
    //     if (!this.$store.state.profile.profileInfo.is_deposited) {
    //       this.cardInfo = appFlow.deposit
    //     } else if (!this.$store.state.profile.profileInfo.mobile_is_verify
    //     || !this.$store.state.profile.profileInfo.profile_complete) {
    //       this.cardInfo = appFlow.profile_complete
    //     } else if (!this.$store.state.profile.profileInfo.document.general.exist) {
    //       this.cardInfo = appFlow.general
    //     } else if (!this.$store.state.profile.profileInfo.document.bank.exist) {
    //       this.cardInfo = appFlow.bank
    //     } else {
    //       this.user_complete = true
    //     }
    //   } else {
    //     this.isUserActive = false
    //   }

    //   this.show = false
    // },
  },
}
</script>
<style>
.card-center-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.buy-sell-btn {
  background-color: #f0c72b !important;
  border: none;
  color: #000 !important;
  animation: shake 10s infinite;
  animation-delay: 2s;

}

@keyframes shake {
  0% {
    transform: scale(1);
  }

  1% {
    transform: scale(1.2);
  }

  2% {
    transform: scale(1);
  }

  3% {
    transform: scale(1);
  }

  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.buy-sell-btn:focus {
  background-color: #f0c72b !important;
}
</style>
